import { Expose, Type } from "class-transformer"
import NetworkPropertyValue from "../property-values/NetworkPropertyValue"
import PropertyDataType from "../../../domain/entities/properties/PropertyDataType"

export default class NetworkProperty {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly name?: string | null

  @Expose()
  readonly code?: string | null

  @Expose()
  readonly uom?: string | null

  @Expose()
  readonly externalCode?: string | null

  @Expose()
  readonly position?: number | null

  @Expose()
  readonly shouldDisplayInProduct: boolean | null | undefined

  @Expose()
  readonly shouldDisplayInFilters: boolean | null | undefined

  @Expose()
  readonly dataType: PropertyDataType | null | undefined

  @Expose()
  @Type(() => NetworkPropertyValue)
  readonly values: NetworkPropertyValue[] | null | undefined

  @Expose()
  readonly isSearchable: boolean | null | undefined

  constructor(parameters?: {
    readonly id?: number | null
    readonly name?: string | null
    readonly code?: string | null
    readonly externalCode?: string | null
    readonly uom?: string | null
    readonly position?: number | null
    readonly values: NetworkPropertyValue[] | null | undefined
    readonly shouldDisplayInProduct: boolean | null | undefined
    readonly shouldDisplayInFilters: boolean | null | undefined
    readonly dataType: PropertyDataType | null | undefined
    readonly isSearchable: boolean | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.name = parameters.name
    this.position = parameters.position
    this.code = parameters.code
    this.externalCode = parameters.externalCode
    this.values = parameters.values
    this.uom = parameters.uom
    this.shouldDisplayInProduct = parameters.shouldDisplayInProduct
    this.shouldDisplayInFilters = parameters.shouldDisplayInFilters
    this.dataType = parameters.dataType
    this.isSearchable = parameters.isSearchable
  }
}
