import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import GetApiLogItemUseCase from "../../../../api-log-items-core/domain/use-cases/GetApiLogItemUseCase"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import ApiLogItem from "../../../../../core/domain/entities/api-log-items/ApiLogItem"
import ApiLogItemErrorsObject from "../../../../../core/domain/entities/api-log-items/ApiLogItemErrorsObject"
import ApiLogItemError from "../../../../../core/domain/entities/api-log-items/ApiLogItemError"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import ApiLogItemServiceType from "../../../../../core/domain/entities/api-log-items/ApiLogItemServiceType"
import ApiLogItemDirection from "../../../../../core/domain/entities/api-log-items/ApiLogItemDirection"
import ApiLogItemActionType from "../../../../../core/domain/entities/api-log-items/ApiLogItemActionType"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import ApiLogItemResultType from "../../../../../core/domain/entities/api-log-items/ApiLogItemResultType"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import TextFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/TextFormField"
import StringFormField from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import DateTimeFormatter from "../../../../../../sqadmin/lib/DateTimeFormatter"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import User from "../../../../../core/domain/entities/users/User"

export default class ApiLogItemViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getApiLogItemUseCase: GetApiLogItemUseCase
  private readonly apiLogItemId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    ApiLogItem,
    ApiLogItemError,
    ApiLogItemErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getApiLogItemUseCase: GetApiLogItemUseCase
    readonly apiLogItemId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getApiLogItemUseCase = parameters.getApiLogItemUseCase
    this.apiLogItemId = parameters.apiLogItemId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    ApiLogItem,
    ApiLogItemError,
    ApiLogItemErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: false,
      buildObject: async() => ({
        id: undefined,
        serviceType: undefined,
        direction: undefined,
        startedAt: undefined,
        finishedAt: undefined,
        action: undefined,
        shortAction: undefined,
        actionType: undefined,
        httpStatus: undefined,
        resultType: undefined,
        requestBody: undefined,
        responseBody: undefined,
        user: undefined
      }),
      getObjectUrl: (apiLogItem) => {
        return this.coreUrlProvider.buildApiLogItemUrl({
          id: apiLogItem.id!
        })
      },
      loadObject: async() => {
        return await this.getApiLogItemUseCase.call({ apiLogItemId: this.apiLogItemId! })
      },
      getErrorsObject: ({ error: apiLogItemError }) => apiLogItemError
        ?.errorsObject,
      formFields: [
        new SingleSelectFormField<
          ApiLogItem,
          ApiLogItemErrorsObject,
          ApiLogItemServiceType
        >({
          getDisabled: () => true,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemServiceType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.service(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.serviceType,
          setValue: (
            apiLogItem: ApiLogItem,
            type: ApiLogItemServiceType | null
          ) => ({
            ...apiLogItem,
            periodType: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemServiceType) => type.valueOf(),
          getOptionText: (
            type: ApiLogItemServiceType
          ) => this.detectApiLogItemServiceTypeDisplayName(type)
        }),
        new TextFormField<ApiLogItem, ApiLogItemErrorsObject>({
          getTitle: () => coreTextProvider.action(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.action,
          setValue: (apiLogItem: ApiLogItem, url: string) => ({
            ...apiLogItem,
            action: url
          }),
          getErrors: () => undefined
        }),
        new SingleSelectFormField<
          ApiLogItem,
          ApiLogItemErrorsObject,
          ApiLogItemDirection
        >({
          getDisabled: () => true,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemDirection),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.direction(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.direction,
          setValue: (
            apiLogItem: ApiLogItem,
            type: ApiLogItemDirection | null
          ) => ({
            ...apiLogItem,
            direction: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemDirection) => type.valueOf(),
          getOptionText: (type: ApiLogItemDirection) => this.detectApiLogItemDirectionDisplayName(type)
        }),
        new SingleSelectFormField<
          ApiLogItem,
          ApiLogItemErrorsObject,
          ApiLogItemActionType
        >({
          getDisabled: () => true,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemActionType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.actionType(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.actionType,
          setValue: (
            apiLogItem: ApiLogItem,
            type: ApiLogItemActionType | null
          ) => ({
            ...apiLogItem,
            actionType: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemActionType) => type.valueOf(),
          getOptionText: (type: ApiLogItemActionType) => this.detectApiLogItemActionTypeDisplayName(type)
        }),
        new NumberFormField<ApiLogItem, ApiLogItemErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.httpStatus(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.httpStatus,
          setValue: (apiLogItem: ApiLogItem, httpStatus: number | null): ApiLogItem => ({
            ...apiLogItem,
            httpStatus
          }),
          getErrors: () => undefined
        }),
        new SingleSelectFormField<
          ApiLogItem,
          ApiLogItemErrorsObject,
          ApiLogItemResultType
        >({
          getDisabled: () => true,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemResultType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.resultType(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.resultType,
          setValue: (
            apiLogItem: ApiLogItem,
            type: ApiLogItemResultType | null
          ) => ({
            ...apiLogItem,
            resultType: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemResultType) => type.valueOf(),
          getOptionText: (type: ApiLogItemResultType) => this.detectApiLogItemResultTypeDisplayName(type)
        }),
        new StringFormField<ApiLogItem, ApiLogItemErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.startedAt(),
          setValue: (apiLogItem: ApiLogItem, _value: string) => ({
            ...apiLogItem
          }),
          getValue: (apiLogItem: ApiLogItem) => {
            if (isBlank(apiLogItem.startedAt)) return ""

            const formatter = new DateTimeFormatter({
              date: apiLogItem.startedAt
            })

            return formatter.formatDateTimeWithSeconds()
          },
          getErrors: () => undefined
        }),
        new StringFormField<ApiLogItem, ApiLogItemErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.finishedAt(),
          setValue: (apiLogItem: ApiLogItem, _value: string) => ({
            ...apiLogItem
          }),
          getValue: (apiLogItem: ApiLogItem) => {
            if (isBlank(apiLogItem.finishedAt)) return ""

            const formatter = new DateTimeFormatter({
              date: apiLogItem.finishedAt
            })

            return formatter.formatDateTimeWithSeconds()
          },
          getErrors: () => undefined
        }),
        new TextFormField<ApiLogItem, ApiLogItemErrorsObject>({
          getTitle: () => coreTextProvider.requestBody(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.requestBody,
          setValue: (apiLogItem: ApiLogItem, value: string) => ({
            ...apiLogItem,
            requestBody: value
          }),
          getErrors: () => undefined
        }),
        new TextFormField<ApiLogItem, ApiLogItemErrorsObject>({
          getTitle: () => coreTextProvider.responseBody(),
          getValue: (apiLogItem: ApiLogItem) => apiLogItem.responseBody,
          setValue: (apiLogItem: ApiLogItem, value: string) => ({
            ...apiLogItem,
            responseBody: value
          }),
          getErrors: () => undefined
        }),
        new StringFormField<ApiLogItem, ApiLogItemErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.user(),
          getValue: (apiLogItem: ApiLogItem) => [
            this.formatUserFullName(apiLogItem.user),
            apiLogItem.user?.emailAddress
          ].filter(value => isPresent(value)).join(", "),
          setValue: (apiLogItem: ApiLogItem, _): ApiLogItem => apiLogItem,
          getErrors: () => undefined
        })
      ]
    })
  }

  private detectApiLogItemServiceTypeDisplayName(type: ApiLogItemServiceType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (type) {
      case ApiLogItemServiceType.DA_DATA:
        return coreTextProvider.daDataServiceType()
      case ApiLogItemServiceType.CDEK:
        return coreTextProvider.cdekServiceType()
      case ApiLogItemServiceType.SMS_AERO:
        return coreTextProvider.smsAeroServiceType()
      case ApiLogItemServiceType.YOOKASSA:
        return coreTextProvider.yookassaServiceType()
      case ApiLogItemServiceType.SYNCHRONIZATION:
        return coreTextProvider.synchronizationServiceType()
      case ApiLogItemServiceType.DOLYAME:
        return coreTextProvider.dolyameServiceType()
      case ApiLogItemServiceType.FIREBASE_CLOUD_MESSAGING:
        return coreTextProvider.firebaseCloudMessagingServiceType()
      case ApiLogItemServiceType.HUAWEI_PUSH_KIT:
        return coreTextProvider.huaweiPushKitServiceType()
      default:
        return coreTextProvider.unknownServiceType()
    }
  }

  private detectApiLogItemDirectionDisplayName(
    type: ApiLogItemDirection | null | undefined
  ): string | null | undefined {
    if (isBlank(type)) return undefined

    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case ApiLogItemDirection.OUTGOING:
        return coreTextProvider.outgoing()
      case ApiLogItemDirection.INCOMING:
        return coreTextProvider.incoming()
    }
  }

  private detectApiLogItemActionTypeDisplayName(
    type: ApiLogItemActionType | null | undefined
  ): string | null | undefined {
    if (isBlank(type)) return undefined

    return type.toString()
  }

  private detectApiLogItemResultTypeDisplayName(
    type: ApiLogItemResultType | null | undefined
  ): string | null | undefined {
    if (isBlank(type)) return undefined

    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case ApiLogItemResultType.CLIENT_ERROR:
        return coreTextProvider.clientError()
      case ApiLogItemResultType.MISSING:
        return coreTextProvider.missing()
      case ApiLogItemResultType.SERVER_ERROR:
        return coreTextProvider.serverError()
      case ApiLogItemResultType.REDIRECT:
        return coreTextProvider.redirect()
      case ApiLogItemResultType.SUCCESS:
        return coreTextProvider.success()
      case ApiLogItemResultType.UNKNOWN:
        return coreTextProvider.unknown()
    }
  }

  private formatUserFullName(user: User | null | undefined): string | null | undefined {
    const profile = user?.profile

    if (isBlank(profile)) return undefined

    return [
      profile?.lastName,
      profile?.firstName,
      profile?.middleName
    ]
      .filter((value: string | null | undefined) => isPresent(value))
      .join(" ")
  }
}
