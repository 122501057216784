import { Expose, Type } from "class-transformer"
import NetworkPropertyValue from "../../../../core/data/entities/property-values/NetworkPropertyValue"
import NetworkPage from "../../../../core/data/entities/pagination/NetworkPage"

export default class NetworkPropertyValuesResponseBody {
  @Expose()
  @Type(() => NetworkPropertyValue)
  readonly propertyValues: NetworkPropertyValue[] | null | undefined

  @Expose()
  @Type(() => NetworkPage)
  readonly page?: NetworkPage | null
}
