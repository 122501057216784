import ApiLogItem from "../../domain/entities/api-log-items/ApiLogItem"
import NetworkApiLogItem from "../entities/api-log-items/NetworkApiLogItem"
import UsersMapper from "./UsersMapper"

export default class ApiLogItemsMapper {
  mapNetworkToDomain({
    apiLogItem
  }: {
    readonly apiLogItem: NetworkApiLogItem
  }): ApiLogItem {
    const usersMapper = new UsersMapper()

    return {
      id: apiLogItem.id,
      serviceType: apiLogItem.serviceType,
      direction: apiLogItem.direction,
      startedAt: apiLogItem.startedAt,
      finishedAt: apiLogItem.finishedAt,
      action: apiLogItem.action,
      shortAction: apiLogItem.shortAction,
      actionType: apiLogItem.actionType,
      httpStatus: apiLogItem.httpStatus,
      resultType: apiLogItem.resultType,
      requestBody: apiLogItem.requestBody,
      responseBody: apiLogItem.responseBody,
      user: apiLogItem.user && usersMapper.mapNetworkToDomain({
        user: apiLogItem.user
      })
    }
  }

  mapDomainToNetwork({
    apiLogItem
  }: {
    readonly apiLogItem: ApiLogItem
  }): NetworkApiLogItem {
    const usersMapper = new UsersMapper()

    return new NetworkApiLogItem({
      id: apiLogItem.id,
      serviceType: apiLogItem.serviceType,
      direction: apiLogItem.direction,
      startedAt: apiLogItem.startedAt,
      finishedAt: apiLogItem.finishedAt,
      action: apiLogItem.action,
      shortAction: apiLogItem.shortAction,
      actionType: apiLogItem.actionType,
      httpStatus: apiLogItem.httpStatus,
      resultType: apiLogItem.resultType,
      requestBody: apiLogItem.requestBody,
      responseBody: apiLogItem.responseBody,
      user: apiLogItem.user && usersMapper.mapDomainToNetwork({
        user: apiLogItem.user
      })
    })
  }
}
