import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import GetOrderProcessingStatusUseCase from "../../../domain/use-cases/GetOrderProcessingStatusUseCase"
import CreateOrderProcessingStatusUseCase from "../../../domain/use-cases/CreateOrderProcessingStatusUseCase"
import UpdateOrderProcessingStatusUseCase from "../../../domain/use-cases/UpdateOrderProcessingStatusUseCase"
import DestroyOrderProcessingStatusUseCase from "../../../domain/use-cases/DestroyOrderProcessingStatusUseCase"
import OrderProcessingStatusError
  from "../../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatusError"
import OrderProcessingStatus from "../../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import OrderProcessingStatusErrorsObject
  from "../../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatusErrorsObject"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BooleanFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/BooleanFormField"

export default class OrderProcessingStatusViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getOrderProcessingStatusUseCase: GetOrderProcessingStatusUseCase
  private readonly createOrderProcessingStatusUseCase: CreateOrderProcessingStatusUseCase
  private readonly updateOrderProcessingStatusUseCase: UpdateOrderProcessingStatusUseCase
  private readonly destroyOrderProcessingStatusUseCase: DestroyOrderProcessingStatusUseCase
  private readonly orderProcessingStatusId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    OrderProcessingStatus,
    OrderProcessingStatusError,
    OrderProcessingStatusErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getOrderProcessingStatusUseCase: GetOrderProcessingStatusUseCase
    readonly createOrderProcessingStatusUseCase: CreateOrderProcessingStatusUseCase
    readonly updateOrderProcessingStatusUseCase: UpdateOrderProcessingStatusUseCase
    readonly destroyOrderProcessingStatusUseCase: DestroyOrderProcessingStatusUseCase
    readonly orderProcessingStatusId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getOrderProcessingStatusUseCase = parameters.getOrderProcessingStatusUseCase
    this.createOrderProcessingStatusUseCase = parameters.createOrderProcessingStatusUseCase
    this.updateOrderProcessingStatusUseCase = parameters.updateOrderProcessingStatusUseCase
    this.destroyOrderProcessingStatusUseCase = parameters.destroyOrderProcessingStatusUseCase
    this.orderProcessingStatusId = parameters.orderProcessingStatusId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    OrderProcessingStatus,
    OrderProcessingStatusError,
    OrderProcessingStatusErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.orderProcessingStatusId),
      buildObject: async() => ({
        id: undefined,
        name: undefined,
        externalCode: undefined,
        displayName: undefined,
        isInProcessing: true,
        isCompleted: false,
        isCreated: false,
        isCanceled: false,
        forOfflineSale: false,
        shouldNotify: false
      }),
      getObjectUrl: (orderProcessingStatus) => {
        return this.coreUrlProvider.buildOrderProcessingStatusUrl({
          id: orderProcessingStatus.id!
        })
      },
      loadObject: async() => {
        return await this.getOrderProcessingStatusUseCase
          .call({ orderProcessingStatusId: this.orderProcessingStatusId! })
      },
      createObject: async({ object: orderProcessingStatus }) => {
        return await this.createOrderProcessingStatusUseCase
          .call({ orderProcessingStatus: orderProcessingStatus })
      },
      updateObject: async({ object: orderProcessingStatus }) => {
        return await this.updateOrderProcessingStatusUseCase.call({
          orderProcessingStatusId: this.orderProcessingStatusId!,
          orderProcessingStatus: orderProcessingStatus
        })
      },
      destroyObject: async() => {
        return await this.destroyOrderProcessingStatusUseCase
          .call({ orderProcessingStatusId: this.orderProcessingStatusId! })
      },
      getErrorsObject: ({ error: orderProcessingStatusError }) => orderProcessingStatusError
        ?.errorsObject,
      formFields: [
        new StringFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getTitle: () => coreTextProvider.name(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.name,
          setValue: (orderProcessingStatus: OrderProcessingStatus, name: string): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            name
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.name
        }),
        new StringFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.externalCode,
          setValue: (orderProcessingStatus: OrderProcessingStatus, value): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            externalCode: value
          }),
          getErrors: (orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject) => {
            return orderProcessingStatusErrorsObject?.attributes?.externalCode
          }
        }),
        new StringFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getTitle: () => coreTextProvider.nameForClient(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.displayName,
          setValue: (orderProcessingStatus: OrderProcessingStatus, displayName: string): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            displayName
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.displayName
        }),
        new BooleanFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getText: () => coreTextProvider.isInProcessing(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.isInProcessing,
          setValue: (orderProcessingStatus: OrderProcessingStatus, isInProcessing: boolean): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            isInProcessing
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.isInProcessing
        }),
        new BooleanFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getText: () => coreTextProvider.isOrderProcessingStatusCreated(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.isCreated,
          setValue: (orderProcessingStatus: OrderProcessingStatus, isCreated: boolean): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            isCreated
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.isCreated
        }),
        new BooleanFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getText: () => coreTextProvider.isOrderProcessingStatusCompleted(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.isCompleted,
          setValue: (orderProcessingStatus: OrderProcessingStatus, isCompleted: boolean): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            isCompleted
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.isCompleted
        }),
        new BooleanFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getText: () => coreTextProvider.isOrderProcessingStatusCanceled(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.isCanceled,
          setValue: (orderProcessingStatus: OrderProcessingStatus, isCanceled: boolean): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            isCanceled
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.isCanceled
        }),
        new BooleanFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getText: () => coreTextProvider.useForOfflineSale(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.forOfflineSale,
          setValue: (orderProcessingStatus: OrderProcessingStatus, forOfflineSale: boolean): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            forOfflineSale
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.forOfflineSale
        }),
        new BooleanFormField<OrderProcessingStatus, OrderProcessingStatusErrorsObject>({
          getText: () => coreTextProvider.shouldNotify(),
          getValue: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.shouldNotify,
          setValue: (orderProcessingStatus: OrderProcessingStatus, shouldNotify: boolean): OrderProcessingStatus => ({
            ...orderProcessingStatus,
            shouldNotify
          }),
          getErrors: (
            orderProcessingStatusErrorsObject?: OrderProcessingStatusErrorsObject
          ) => orderProcessingStatusErrorsObject?.attributes?.shouldNotify
        })
      ]
    })
  }
}
