import PropertyValuesRepository from "../../domain/repositories/PropertyValuesRepository"
import PropertyValuesNetworkSource, { GetPropertyValuesNetworkResult } from "../sources/PropertyValuesNetworkSource"
import { GetPropertyValuesParameters, GetPropertyValuesResult } from "../../domain/use-cases/GetPropertyValuesUseCase"
import PropertyValuesFiltersMapper from "../mappers/PropertyValuesFiltersMapper"
import PropertyValuesMapper from "../../../../core/data/mappers/PropertyValuesMapper"
import NetworkPropertyValue from "../../../../core/data/entities/property-values/NetworkPropertyValue"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"

export default class DefaultPropertyValuesRepository implements PropertyValuesRepository {
  private readonly propertyValuesNetworkSource: PropertyValuesNetworkSource

  constructor(parameters: {
    readonly propertyValuesNetworkSource: PropertyValuesNetworkSource
  }) {
    this.propertyValuesNetworkSource = parameters.propertyValuesNetworkSource
  }

  async getPropertyValues({
    query,
    propertyId,
    pagination
  }: GetPropertyValuesParameters): Promise<GetPropertyValuesResult> {
    const result: GetPropertyValuesNetworkResult = await this.propertyValuesNetworkSource.getPropertyValues({
      filter: new PropertyValuesFiltersMapper().mapDomainToNetwork({ query }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({ pagination }),
      propertyId
    })

    switch (result.type) {
      case "success": {
        const propertyValuesMapper = new PropertyValuesMapper()

        return {
          type: "success",
          data: {
            objects: result.data.propertyValues!.map((propertyValue: NetworkPropertyValue) => {
              return propertyValuesMapper.mapNetworkToDomain({ value: propertyValue })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      }
      default:
        return result
    }
  }
}
