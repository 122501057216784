import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import GetPaymentStatusUseCase from "../../../../payment-statuses-core/domain/use-cases/GetPaymentStatusUseCase"
import CreatePaymentStatusUseCase from "../../../../payment-statuses-core/domain/use-cases/CreatePaymentStatusUseCase"
import UpdatePaymentStatusUseCase from "../../../../payment-statuses-core/domain/use-cases/UpdatePaymentStatusUseCase"
import DestroyPaymentStatusUseCase from "../../../../payment-statuses-core/domain/use-cases/DestroyPaymentStatusUseCase"
import PaymentStatus from "../../../../../core/domain/entities/payment-statuses/PaymentStatus"
import PaymentStatusError from "../../../../../core/domain/entities/payment-statuses/PaymentStatusError"
import PaymentStatusErrorsObject from "../../../../../core/domain/entities/payment-statuses/PaymentStatusErrorsObject"

export default class PaymentStatusViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getPaymentStatusUseCase: GetPaymentStatusUseCase
  private readonly createPaymentStatusUseCase: CreatePaymentStatusUseCase
  private readonly updatePaymentStatusUseCase: UpdatePaymentStatusUseCase
  private readonly destroyPaymentStatusUseCase: DestroyPaymentStatusUseCase
  private readonly paymentStatusId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    PaymentStatus,
    PaymentStatusError,
    PaymentStatusErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getPaymentStatusUseCase: GetPaymentStatusUseCase
    readonly createPaymentStatusUseCase: CreatePaymentStatusUseCase
    readonly updatePaymentStatusUseCase: UpdatePaymentStatusUseCase
    readonly destroyPaymentStatusUseCase: DestroyPaymentStatusUseCase
    readonly paymentStatusId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getPaymentStatusUseCase = parameters.getPaymentStatusUseCase
    this.createPaymentStatusUseCase = parameters.createPaymentStatusUseCase
    this.updatePaymentStatusUseCase = parameters.updatePaymentStatusUseCase
    this.destroyPaymentStatusUseCase = parameters.destroyPaymentStatusUseCase
    this.paymentStatusId = parameters.paymentStatusId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    PaymentStatus,
    PaymentStatusError,
    PaymentStatusErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.paymentStatusId),
      buildObject: async() => ({
        id: undefined,
        name: undefined,
        externalCode: undefined,
        displayName: undefined
      }),
      getObjectUrl: (paymentStatus) => {
        return this.coreUrlProvider.buildPaymentStatusUrl({
          id: paymentStatus.id!
        })
      },
      loadObject: async() => {
        return await this.getPaymentStatusUseCase
          .call({ paymentStatusId: this.paymentStatusId! })
      },
      createObject: async({ object: paymentStatus }) => {
        return await this.createPaymentStatusUseCase
          .call({ paymentStatus: paymentStatus })
      },
      updateObject: async({ object: paymentStatus }) => {
        return await this.updatePaymentStatusUseCase.call({
          paymentStatusId: this.paymentStatusId!,
          paymentStatus: paymentStatus
        })
      },
      destroyObject: async() => {
        return await this.destroyPaymentStatusUseCase
          .call({ paymentStatusId: this.paymentStatusId! })
      },
      getErrorsObject: ({ error: paymentStatusError }) => paymentStatusError
        ?.errorsObject,
      formFields: [
        new StringFormField<PaymentStatus, PaymentStatusErrorsObject>({
          getTitle: () => coreTextProvider.name(),
          getValue: (paymentStatus: PaymentStatus) => paymentStatus.name,
          setValue: (paymentStatus: PaymentStatus, name: string): PaymentStatus => ({
            ...paymentStatus,
            name
          }),
          getErrors: (
            paymentStatusErrorsObject?: PaymentStatusErrorsObject
          ) => paymentStatusErrorsObject?.attributes?.name
        }),
        new StringFormField<PaymentStatus, PaymentStatusErrorsObject>({
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (paymentStatus: PaymentStatus) => paymentStatus.externalCode,
          setValue: (paymentStatus: PaymentStatus, value): PaymentStatus => ({
            ...paymentStatus,
            externalCode: value
          }),
          getErrors: (paymentStatusErrorsObject?: PaymentStatusErrorsObject) => {
            return paymentStatusErrorsObject?.attributes?.externalCode
          }
        }),
        new StringFormField<PaymentStatus, PaymentStatusErrorsObject>({
          getTitle: () => coreTextProvider.nameForClient(),
          getValue: (paymentStatus: PaymentStatus) => paymentStatus.displayName,
          setValue: (paymentStatus: PaymentStatus, displayName: string): PaymentStatus => ({
            ...paymentStatus,
            displayName
          }),
          getErrors: (
            paymentStatusErrorsObject?: PaymentStatusErrorsObject
          ) => paymentStatusErrorsObject?.attributes?.displayName
        })
      ]
    })
  }
}
