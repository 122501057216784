import SingleSelectFormField
  from "../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import ProductsSetCondition from "../../domain/entities/products-set-condition/ProductsSetCondition"
import ProductsSetErrorsObject from "../../domain/entities/products-sets/ProductsSetErrorsObject"
import ProductsSetConditionType from "../../domain/entities/products-set-condition/ProductsSetConditionType"
import isBlank from "../../../../sqadmin/lib/isBlank"
import ProductCategory from "../../domain/entities/product-categories/ProductCategory"
import ListFormField
  from "../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import ProductsSetProperty from "../../domain/entities/products-set-properties/ProductsSetProperty"
import ProductsSetPropertyErrorsObject
  from "../../domain/entities/products-set-properties/ProductsSetPropertyErrorsObject"
import isPresent from "../../../../sqadmin/lib/isPresent"
import { v4 as uuidv4 } from "uuid"
import Property from "../../domain/entities/properties/Property"
import ProductsSetProduct from "../../domain/entities/products-set-products/ProductsSetProduct"
import ProductsSetProductErrorsObject from "../../domain/entities/products-set-products/ProductsSetProductErrorsObject"
import MultiSelectFormField
  from "../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/MultiSelectFormField"
import PropertyValue from "../../domain/entities/property-values/PropertyValue"
import ProductsSet from "../../domain/entities/products-sets/ProductsSet"
import CoreI18n from "../../i18n/CoreI18n"
import CoreTextProvider from "../../i18n/CoreTextProvider"
import assertNever from "../../../../sqadmin/lib/assertNever"
import Product from "../../domain/entities/products/Product"
import { GetObjectsPageResult } from "../../../../sqadmin/features/objects/domain/results/GetObjectsPageResult"
import ProductsFilter from "../../domain/entities/products/ProductsFilter"

type GetPropertiesType = ({
  query,
  lastObjectId
}: {
  readonly query: string | undefined | null
  readonly lastObjectId: number | undefined | null
}) => Promise<GetObjectsPageResult<Property>>

type GetProductCategoriesType = ({
  query,
  lastObjectId
}: {
  readonly query: string | undefined | null
  readonly lastObjectId: number | undefined | null
}) => Promise<GetObjectsPageResult<ProductCategory>>

type GetProductsType = ({
  filter,
  query,
  lastObjectId
}: {
  readonly filter: ProductsFilter | null | undefined
  readonly query: string | undefined | null
  readonly lastObjectId: number | undefined | null
}) => Promise<GetObjectsPageResult<Product>>

type GetPropertyValuesType = ({
  query,
  propertyId,
  lastObjectId
}: {
  readonly query: string | undefined | null
  readonly propertyId: number
  readonly lastObjectId: number | undefined | null
}) => Promise<GetObjectsPageResult<PropertyValue>>

type FindProductsSetForConditionType = ({
  productsSetCondition
}: {
  readonly productsSetCondition: ProductsSetCondition
}) => ProductsSet | undefined

export class ProductsSetConditionsFields {
  private readonly coreI18n: CoreI18n
  private readonly getProductCategories: GetProductCategoriesType
  private readonly getProperties: GetPropertiesType
  private readonly getPropertyValues: GetPropertyValuesType
  private readonly getProducts: GetProductsType
  private readonly findProductsSetForCondition: FindProductsSetForConditionType

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly getPropertyValues: GetPropertyValuesType
    readonly getProducts: GetProductsType
    readonly getProductCategories: GetProductCategoriesType
    readonly getProperties: GetPropertiesType
    readonly findProductsSetForCondition: FindProductsSetForConditionType
  }) {
    this.coreI18n = parameters.coreI18n
    this.getProductCategories = parameters.getProductCategories
    this.getProperties = parameters.getProperties
    this.getPropertyValues = parameters.getPropertyValues
    this.getProducts = parameters.getProducts
    this.findProductsSetForCondition = parameters.findProductsSetForCondition
  }

  createFields() {
    return [
      this.createProductsSetConditionTypeFormField(),
      this.createProductsSetProductCategoryFormField(),
      this.createProductsSetPropertiesFormField(),
      this.createProductsSetProductsFormField()
    ]
  }

  private createProductsSetConditionTypeFormField() {
    return new SingleSelectFormField<ProductsSetCondition, ProductsSetErrorsObject, ProductsSetConditionType>({
      isSearchBarVisible: false,
      getObjects: async({ parentObject: productsSetCondition }) => {
        return {
          type: "success",
          data: {
            objects: this.calculateNotSelectedProductsSetConditionTypes({ productsSetCondition }),
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().productsSetConditionType(),
      getValue: (productsSetCondition: ProductsSetCondition) => productsSetCondition.type,
      setValue: (
        productsSetCondition: ProductsSetCondition,
        productsSetConditionType: ProductsSetConditionType | null
      ): ProductsSetCondition => {
        if (isBlank(productsSetConditionType)) return productsSetCondition

        return {
          ...productsSetCondition,
          type: productsSetConditionType
        }
      },
      getErrors: () => undefined,
      getOptionId: (productsSetConditionType: ProductsSetConditionType) => {
        return productsSetConditionType.valueOf()
      },
      getOptionText: (productsSetConditionType: ProductsSetConditionType) => {
        return this.detectProductsSetConditionTypeDisplayName(productsSetConditionType)
      }
    })
  }

  private createProductsSetProductCategoryFormField() {
    return new SingleSelectFormField<ProductsSetCondition, ProductsSetErrorsObject, ProductCategory>({
      getVisible: (productsSetCondition: ProductsSetCondition): boolean => {
        return productsSetCondition.type === ProductsSetConditionType.PRODUCT_CATEGORY
      },
      getObjects: async({
        query,
        lastObject
      }) => this.getProductCategories({ query, lastObjectId: lastObject?.id }),
      getTitle: () => this.coreI18n.getTextProvider().productCategory(),
      getValue: (productsSetCondition: ProductsSetCondition): ProductCategory | null | undefined => {
        return productsSetCondition.type === ProductsSetConditionType.PRODUCT_CATEGORY ?
          productsSetCondition.productCategory : undefined
      },
      setValue: (
        productsSetCondition: ProductsSetCondition,
        productCategory: ProductCategory | null
      ): ProductsSetCondition => {
        return productsSetCondition.type === ProductsSetConditionType.PRODUCT_CATEGORY ? {
          ...productsSetCondition,
          productCategoryId: productCategory && productCategory.id,
          productCategory
        } : this.createProductsSetCondition()
      },
      getErrors: (productsSetErrorsObject?: ProductsSetErrorsObject) => {
        return productsSetErrorsObject?.attributes?.productCategoryId
      },
      getOptionId: (category: ProductCategory) => category.id!.toString(),
      getOptionText: (category: ProductCategory) => category.name
    })
  }

  private createProductsSetPropertiesFormField() {
    return new ListFormField<
      ProductsSetCondition,
      ProductsSetErrorsObject,
      ProductsSetProperty,
      ProductsSetPropertyErrorsObject
    >({
      getVisible: (productsSetCondition: ProductsSetCondition): boolean => {
        return productsSetCondition.type === ProductsSetConditionType.PROPERTIES_VALUES
      },
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getTitle: () => this.coreI18n.getTextProvider().propertiesValues(),
      getValue: (productsSetCondition: ProductsSetCondition) => {
        return productsSetCondition.type === ProductsSetConditionType.PROPERTIES_VALUES ?
          productsSetCondition.productsSetProperties : []
      },
      getErrors: (productsSetErrorsObject) => {
        return productsSetErrorsObject?.attributes?.productsSetProperties
      },
      setValue: (
        productsSetCondition: ProductsSetCondition,
        productsSetProperties: ProductsSetProperty[] | null
      ): ProductsSetCondition => {
        return productsSetCondition.type === ProductsSetConditionType.PROPERTIES_VALUES ? {
          ...productsSetCondition,
          productsSetProperties
        } : this.createProductsSetCondition()
      },
      getNestedObjectId: (productsSetProperty: ProductsSetProperty) => productsSetProperty.clientId!,
      getNestedObjectTitle: (_: ProductsSetProperty, index: number) => {
        return this.coreI18n.getTextProvider().propertyWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (
        productsSetProperty: ProductsSetProperty,
        productsSetErrorsObject?: ProductsSetErrorsObject
      ) => {
        return productsSetErrorsObject?.productsSetProperties?.find(
          (productsSetPropertyErrorsObject): boolean => {
            return productsSetPropertyErrorsObject.clientId === productsSetProperty.clientId
          })
      },
      hasNestedErrorsObjects: (productsSetErrorsObject?: ProductsSetErrorsObject) => {
        return isPresent(productsSetErrorsObject?.productsSetProperties)
      },
      buildNewValue: (): ProductsSetProperty => ({
        clientId: uuidv4(),
        property: undefined,
        propertyId: undefined,
        propertyValues: [],
        propertyValueIds: []
      }),
      fields: [
        this.createBlockProductsSetPropertyFormField(),
        this.createBlockProductsSetPropertyValuesField()
      ]
    })
  }

  private createBlockProductsSetPropertyFormField() {
    return new SingleSelectFormField<ProductsSetProperty, ProductsSetPropertyErrorsObject, Property>({
      getObjects: async({
        query,
        lastObject
      }) => await this.getProperties({ query, lastObjectId: lastObject?.id }),
      getTitle: () => "",
      getValue: (productsSetProperty: ProductsSetProperty): Property | null | undefined => productsSetProperty.property,
      setValue: (
        productsSetProperty: ProductsSetProperty,
        property: Property | null
      ): ProductsSetProperty => {
        return {
          ...productsSetProperty,
          property: property,
          propertyId: property?.id,
          propertyValueIds: [],
          propertyValues: []
        }
      },
      getErrors: (productsSetPropertyErrorsObject) => productsSetPropertyErrorsObject?.attributes?.propertyId,
      getOptionId: (property: Property) => property.id!.toString(),
      getOptionText: (property: Property) => property.name
    })
  }

  private createBlockProductsSetPropertyValuesField() {
    return new MultiSelectFormField<ProductsSetProperty, ProductsSetPropertyErrorsObject, PropertyValue>({
      getDisabled: (productsSetProperty: ProductsSetProperty) => isBlank(productsSetProperty.propertyId),
      getObjects: async({
        query,
        parentObject,
        lastObject: lastPropertyValue
      }) => await this.getPropertyValues({
        query,
        propertyId: parentObject!.propertyId!,
        lastObjectId: lastPropertyValue?.id ?? undefined
      }),
      getTitle: () => this.coreI18n.getTextProvider().values(),
      getValue: (productsSetProperty: ProductsSetProperty): PropertyValue[] | null | undefined => {
        return productsSetProperty.propertyValues
      },
      setValue: (
        productsSetProperty: ProductsSetProperty,
        propertyValues: PropertyValue[] | null
      ): ProductsSetProperty => {
        return {
          ...productsSetProperty,
          propertyValues: propertyValues,
          propertyValueIds: propertyValues?.map((propertyValue) => propertyValue.id!)
        }
      },
      getErrors: (productsSetPropertyErrorsObject?: ProductsSetPropertyErrorsObject) => productsSetPropertyErrorsObject
        ?.attributes
        ?.propertyValueIds,
      getOptionId: (propertyValue: PropertyValue) => propertyValue.id!.toString(),
      getOptionText: (propertyValue: PropertyValue) => propertyValue.name
    })
  }

  private createProductsSetProductsFormField() {
    return new ListFormField<
      ProductsSetCondition,
      ProductsSetErrorsObject,
      ProductsSetProduct,
      ProductsSetProductErrorsObject
    >({
      getVisible: (productsSetCondition: ProductsSetCondition): boolean => {
        return productsSetCondition.type === ProductsSetConditionType.PRODUCTS
      },
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getTitle: () => this.coreI18n.getTextProvider().products(),
      getValue: (productsSetCondition: ProductsSetCondition) => {
        return productsSetCondition.type === ProductsSetConditionType.PRODUCTS ?
          productsSetCondition.productsSetProducts : []
      },
      getErrors: (productsSetErrorsObject?: ProductsSetErrorsObject) => {
        return productsSetErrorsObject?.attributes?.productsSetProducts
      },
      setValue: (
        productsSetCondition: ProductsSetCondition,
        productsSetProducts: ProductsSetProduct[] | null
      ): ProductsSetCondition => {
        return productsSetCondition.type === ProductsSetConditionType.PRODUCTS ? {
          ...productsSetCondition,
          productsSetProducts
        } : this.createProductsSetCondition()
      },
      getNestedObjectId: (productsSetProduct: ProductsSetProduct) => productsSetProduct.clientId!,
      getNestedObjectTitle: (_: ProductsSetProduct, index: number) => {
        return this.coreI18n.getTextProvider().productWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (
        productsSetProduct: ProductsSetProduct,
        productsSetErrorsObject?: ProductsSetErrorsObject
      ) => {
        return productsSetErrorsObject?.productsSetProducts?.find(
          (productsSetProductErrorsObject: ProductsSetProductErrorsObject): boolean => {
            return productsSetProductErrorsObject.clientId === productsSetProduct.clientId
          })
      },
      hasNestedErrorsObjects: (productsSetErrorsObject?: ProductsSetErrorsObject) => {
        return isPresent(productsSetErrorsObject?.productsSetProducts)
      },
      getPosition: (productsSetProduct: ProductsSetProduct) => productsSetProduct.position!,
      setPosition: (productsSetProduct: ProductsSetProduct, position: number): ProductsSetProduct => {
        return { ...productsSetProduct, position }
      },
      isSwitchPositionVisible: true,
      buildNewValue: () => ({
        id: undefined,
        clientId: uuidv4(),
        product: undefined,
        productId: undefined,
        position: undefined
      }),
      fields: [
        this.createBlockProductsSetProductFormField()
      ]
    })
  }

  private calculateNotSelectedProductsSetConditionTypes({
    productsSetCondition
  }: {
    readonly productsSetCondition: ProductsSetCondition
  }): ProductsSetConditionType[] {
    const productsSet: ProductsSet | undefined = this.findProductsSetForCondition({ productsSetCondition })

    if (isBlank(productsSet)) {
      return []
    }

    const anotherProductsSetConditions: ProductsSetCondition[] = (productsSet.conditions ?? [])
      .filter((anotherProductsSetCondition: ProductsSetCondition): boolean => {
        return anotherProductsSetCondition.clientId !== productsSetCondition.clientId
      })

    const alreadySelectedProductsSetConditionTypes: ProductsSetConditionType[] = anotherProductsSetConditions
      .map((productsSetCondition: ProductsSetCondition) => productsSetCondition.type)
      .filter((
        productsSetConditionType: ProductsSetConditionType | null | undefined
      ): productsSetConditionType is ProductsSetConditionType => isPresent(productsSetConditionType))

    return Object.values(ProductsSetConditionType)
      .filter((productsSetConditionType: ProductsSetConditionType) => {
        return !alreadySelectedProductsSetConditionTypes.includes(productsSetConditionType)
      })
  }

  private detectProductsSetConditionTypeDisplayName(productsSetConditionType: ProductsSetConditionType): string {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (productsSetConditionType) {
      case ProductsSetConditionType.PRODUCT_CATEGORY:
        return coreTextProvider.productsSetConditionTypeProductCategory()
      case ProductsSetConditionType.PROPERTIES_VALUES:
        return coreTextProvider.productsSetConditionTypePropertiesValues()
      case ProductsSetConditionType.PRODUCTS:
        return coreTextProvider.productsSetConditionTypeProducts()
      case ProductsSetConditionType.PRODUCTS_CAN_BE_ORDERED_WITH_BONUS_POINTS:
        return coreTextProvider.productsSetConditionTypeProductsCanBeOrderedWithBonusPoints()
      default:
        assertNever(productsSetConditionType)
    }
  }

  private createBlockProductsSetProductFormField() {
    return new SingleSelectFormField<ProductsSetProduct, ProductsSetProductErrorsObject, Product>({
      getObjects: async({
        query,
        lastObject
      }) => {
        return await this.getProducts({
          filter: { isArchivedFilterValue: undefined, category: undefined },
          query,
          lastObjectId: lastObject?.id
        })
      },
      getValue: (productsSetProduct: ProductsSetProduct) => productsSetProduct.product,
      setValue: (productsSetProduct: ProductsSetProduct, product: Product | null): ProductsSetProduct => {
        return { ...productsSetProduct, product, productId: product && product.id }
      },
      getErrors: (productsSetProductErrorsObject?: ProductsSetProductErrorsObject) => {
        return productsSetProductErrorsObject?.attributes?.productId
      },
      getOptionId: (product: Product) => product.id!.toString(),
      getOptionText: (product: Product) => {
        return [product.externalCode, product.name, product.description]
          .filter(value => isPresent(value))
          .join("\n")
      }
    })
  }

  private createProductsSetCondition(): ProductsSetCondition {
    return {
      clientId: uuidv4()
    }
  }
}
