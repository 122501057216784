import NetworkProperty from "../entities/properties/NetworkProperty"
import Property from "../../domain/entities/properties/Property"
import PropertyValuesMapper from "./PropertyValuesMapper"

export default class PropertiesMapper {
  mapNetworkToDomain({
    property
  }: {
    readonly property: NetworkProperty
  }): Property {
    return {
      id: property.id,
      name: property.name,
      externalCode: property.externalCode,
      position: property.position,
      uom: property.uom,
      shouldDisplayInFilters: property.shouldDisplayInFilters,
      shouldDisplayInProduct: property.shouldDisplayInProduct,
      values: property.values && property.values.map(value => {
        return new PropertyValuesMapper().mapNetworkToDomain({ value })
      }),
      dataType: property.dataType,
      isSearchable: property.isSearchable
    }
  }

  mapDomainToNetwork({
    property
  }: {
    readonly property: Property
  }): NetworkProperty {
    return new NetworkProperty({
      id: property.id,
      name: property.name,
      externalCode: property.externalCode,
      position: property.position,
      uom: property.uom,
      shouldDisplayInFilters: property.shouldDisplayInFilters,
      shouldDisplayInProduct: property.shouldDisplayInProduct,
      values: property.values && property.values.map(value => {
        return new PropertyValuesMapper().mapDomainToNetwork({ value })
      }),
      dataType: property.dataType,
      isSearchable: property.isSearchable
    })
  }
}
