import { Expose, Type } from "class-transformer"
import NetworkPropertyValuesRequestFilter from "./NetworkPropertyValuesRequestFilter"
import NetworkLastItemPagination from "../../../../core/data/entities/pagination/NetworkLastItemPagination"

export default class NetworkPropertyValuesRequestQuery {
  @Expose()
  @Type(() => NetworkPropertyValuesRequestFilter)
  readonly filter: NetworkPropertyValuesRequestFilter | null | undefined

  @Expose()
  readonly sort: string | null | undefined

  @Expose()
  @Type(() => NetworkLastItemPagination)
  readonly pagination?: NetworkLastItemPagination | null

  constructor(parameters: {
    readonly filter: NetworkPropertyValuesRequestFilter | null | undefined
    readonly sort: string | null | undefined
    readonly pagination?: NetworkLastItemPagination | null
  }) {
    this.filter = parameters.filter
    this.sort = parameters.sort
    this.pagination = parameters.pagination
  }
}
