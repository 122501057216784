import { Expose, Transform, Type } from "class-transformer"
import transformDate from "../../../../../sqadmin/lib/transformDate"
import ApiLogItemServiceType from "../../../domain/entities/api-log-items/ApiLogItemServiceType"
import ApiLogItemDirection from "../../../domain/entities/api-log-items/ApiLogItemDirection"
import ApiLogItemActionType from "../../../domain/entities/api-log-items/ApiLogItemActionType"
import ApiLogItemResultType from "../../../domain/entities/api-log-items/ApiLogItemResultType"
import NetworkUser from "../users/NetworkUser"

export default class NetworkApiLogItem {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly serviceType: ApiLogItemServiceType | null | undefined

  @Expose()
  readonly direction: ApiLogItemDirection | null | undefined

  @Expose()
  @Transform(transformDate)
  readonly startedAt: Date | null | undefined

  @Expose()
  @Transform(transformDate)
  readonly finishedAt: Date | null | undefined

  @Expose()
  readonly action: string | null | undefined

  @Expose()
  readonly shortAction: string | null | undefined

  @Expose()
  readonly actionType: ApiLogItemActionType | null | undefined

  @Expose()
  readonly httpStatus: number | null | undefined

  @Expose()
  readonly resultType: ApiLogItemResultType | null | undefined

  @Expose()
  readonly requestBody: string | null | undefined

  @Expose()
  readonly responseBody: string | null | undefined

  @Expose()
  @Type(() => NetworkUser)
  readonly user: NetworkUser | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly serviceType: ApiLogItemServiceType | null | undefined
    readonly direction: ApiLogItemDirection | null | undefined
    readonly startedAt: Date | null | undefined
    readonly finishedAt: Date | null | undefined
    readonly action: string | null | undefined
    readonly shortAction: string | null | undefined
    readonly actionType: ApiLogItemActionType | null | undefined
    readonly httpStatus: number | null | undefined
    readonly resultType: ApiLogItemResultType | null | undefined
    readonly requestBody: string | null | undefined
    readonly responseBody: string | null | undefined
    readonly user: NetworkUser | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.serviceType = parameters.serviceType
    this.direction = parameters.direction
    this.startedAt = parameters.startedAt
    this.finishedAt = parameters.finishedAt
    this.action = parameters.action
    this.shortAction = parameters.shortAction
    this.actionType = parameters.actionType
    this.httpStatus = parameters.httpStatus
    this.resultType = parameters.resultType
    this.requestBody = parameters.requestBody
    this.responseBody = parameters.responseBody
    this.user = parameters.user
  }
}
