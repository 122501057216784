import OrderProcessingStatus from "../../domain/entities/order-processing-statuses/OrderProcessingStatus"
import NetworkOrderProcessingStatus from "../entities/order-processing-statuses/NetworkOrderProcessingStatus"

export default class OrderProcessingStatusesMapper {
  mapNetworkToDomain({
    orderProcessingStatus
  }: {
    readonly orderProcessingStatus: NetworkOrderProcessingStatus
  }): OrderProcessingStatus {
    return {
      id: orderProcessingStatus.id,
      name: orderProcessingStatus.name,
      externalCode: orderProcessingStatus.externalCode,
      displayName: orderProcessingStatus.displayName,
      isInProcessing: orderProcessingStatus.isInProcessing,
      isCompleted: orderProcessingStatus.isCompleted,
      isCanceled: orderProcessingStatus.isCanceled,
      isCreated: orderProcessingStatus.isCreated,
      forOfflineSale: orderProcessingStatus.forOfflineSale,
      shouldNotify: orderProcessingStatus.shouldNotify
    }
  }

  mapDomainToNetwork({
    orderProcessingStatus
  }: {
    readonly orderProcessingStatus: OrderProcessingStatus
  }): NetworkOrderProcessingStatus {
    return new NetworkOrderProcessingStatus({
      id: orderProcessingStatus.id,
      name: orderProcessingStatus.name,
      externalCode: orderProcessingStatus.externalCode,
      displayName: orderProcessingStatus.displayName,
      isInProcessing: orderProcessingStatus.isInProcessing,
      isCompleted: orderProcessingStatus.isCompleted,
      isCanceled: orderProcessingStatus.isCanceled,
      isCreated: orderProcessingStatus.isCreated,
      forOfflineSale: orderProcessingStatus.forOfflineSale,
      shouldNotify: orderProcessingStatus.shouldNotify
    })
  }
}
