import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import SubscribeToObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/SubscribeToObjectsEventsUseCase"
import UnsubscribeFromObjectsEventsUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/UnsubscribeFromObjectsEventsUseCase"
import ObjectsPresentationLogicParameters
  from "../../../../../../sqadmin/features/objects/presentation/entities/objects/ObjectsPresentationLogicParameters"
import ObjectsPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectsPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import {
  ObjectsPageViewState
} from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectsViewState"
import { ActionObservable } from "../../../../../../sqadmin/lib/view-model/ActionObservable"
import {
  ObjectsViewAction
} from "../../../../../../sqadmin/features/objects/presentation/view-actions/ObjectsViewAction"
import autoBind from "auto-bind"
import ObjectsViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectsViewEvent"
import TableColumn from "../../../../../../sqadmin/features/objects/presentation/entities/tables/TableColumn"
import GetApiLogItemsUseCase from "../../../../api-log-items-core/domain/use-cases/GetApiLogItemsUseCase"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import ApiLogItem from "../../../../../core/domain/entities/api-log-items/ApiLogItem"
import ApiLogItemServiceType from "../../../../../core/domain/entities/api-log-items/ApiLogItemServiceType"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import TextTableValue
  from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import ApiLogItemDirection from "../../../../../core/domain/entities/api-log-items/ApiLogItemDirection"
import DateTimeTableValue, {
  DateTimeTableValueFormat
} from "../../../../../../sqadmin/features/objects/presentation/entities/tables/table-value-by-type/DateTimeTableValue"
import ApiLogItemActionType from "../../../../../core/domain/entities/api-log-items/ApiLogItemActionType"
import ApiLogItemResultType from "../../../../../core/domain/entities/api-log-items/ApiLogItemResultType"
import ApiLogItemsFilter from "../../../../../core/domain/entities/api-log-items/ApiLogItemsFilter"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import DateFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DateFormField"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import User from "../../../../../core/domain/entities/users/User"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import GetUsersUseCase from "../../../../users-core/domain/use-cases/GetUsersUseCase"
import UserRoleType from "../../../../../core/domain/entities/users/UserRoleType"

export default class ApiLogItemsViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
  private readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
  private readonly getApiLogItemsUseCase: GetApiLogItemsUseCase
  private readonly getUsersUseCase: GetUsersUseCase
  private readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  private readonly objectsPresentationLogic: ObjectsPresentationLogic<ApiLogItem, ApiLogItemsFilter>

  readonly observableObjectsPageViewState: StateObservable<ObjectsPageViewState<ApiLogItem>>
  readonly observableObjectsViewAction: ActionObservable<ObjectsViewAction>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly subscribeToObjectsEventsUseCase: SubscribeToObjectsEventsUseCase
    readonly unsubscribeFromObjectsEventsUseCase: UnsubscribeFromObjectsEventsUseCase
    readonly getApiLogItemsUseCase: GetApiLogItemsUseCase
    readonly getUsersUseCase: GetUsersUseCase
    readonly objectsPresentationLogicParameters: ObjectsPresentationLogicParameters
  }) {
    super()
    autoBind(this)
    this.coreI18n = parameters.coreI18n
    this.subscribeToObjectsEventsUseCase = parameters.subscribeToObjectsEventsUseCase
    this.unsubscribeFromObjectsEventsUseCase = parameters.unsubscribeFromObjectsEventsUseCase
    this.getApiLogItemsUseCase = parameters.getApiLogItemsUseCase
    this.getUsersUseCase = parameters.getUsersUseCase
    this.objectsPresentationLogicParameters = parameters.objectsPresentationLogicParameters
    this.objectsPresentationLogic = this.createObjectsPresentationLogic()
    this.observableObjectsPageViewState = this.objectsPresentationLogic.observableObjectsPageViewState
    this.observableObjectsViewAction = this.objectsPresentationLogic.observableObjectsViewAction
  }

  protected onDestroy() {
    super.onDestroy()
    this.objectsPresentationLogic.destroy()
  }

  onObjectsViewEvent(objectsViewEvent: ObjectsViewEvent) {
    this.objectsPresentationLogic.onObjectsViewEvent(objectsViewEvent)
  }

  private createObjectsPresentationLogic(): ObjectsPresentationLogic<ApiLogItem, ApiLogItemsFilter> {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    const coreUrlProvider = new CoreUrlProvider()

    return new ObjectsPresentationLogic<ApiLogItem, ApiLogItemsFilter>({
      ...this.objectsPresentationLogicParameters,
      subscribeToObjectsEventsUseCase: this.subscribeToObjectsEventsUseCase,
      unsubscribeFromObjectsEventsUseCase: this.unsubscribeFromObjectsEventsUseCase,
      getObjects: async({
        query,
        sort,
        filterObject,
        lastObject: lastApiLogItem
      }) => {
        return await this.getApiLogItemsUseCase.call({
          query: query,
          filter: {
            serviceType: filterObject?.serviceType,
            direction: filterObject?.direction,
            httpStatus: filterObject?.httpStatus,
            actionType: filterObject?.actionType,
            resultType: filterObject?.resultType,
            startedAt: filterObject?.startedAt,
            user: filterObject?.user
          },
          sort,
          pagination: { id: lastApiLogItem?.id ?? undefined }
        })
      },
      getObjectId: (apiLogItem: ApiLogItem) => apiLogItem.id!.toString(),
      columns: [
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.id(),
          createValue: (apiLogItem: ApiLogItem) => new TextTableValue({
            value: apiLogItem.id?.toString()
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.service(),
          createValue: (apiLogItem: ApiLogItem) => new TextTableValue({
            value: this.detectApiLogItemServiceTypeDisplayName(apiLogItem.serviceType)
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.direction(),
          createValue: (apiLogItem: ApiLogItem) => new TextTableValue({
            value: this.detectApiLogItemDirectionDisplayName(apiLogItem.direction)
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.startedAt(),
          createValue: (apiLogItem: ApiLogItem) => new DateTimeTableValue({
            value: apiLogItem.startedAt,
            format: DateTimeTableValueFormat.DATE_TIME_FULL
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.finishedAt(),
          createValue: (apiLogItem: ApiLogItem) => new DateTimeTableValue({
            value: apiLogItem.finishedAt,
            format: DateTimeTableValueFormat.DATE_TIME_FULL
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.actionType(),
          createValue: (apiLogItem: ApiLogItem) => new TextTableValue({
            value: this.detectApiLogItemActionTypeDisplayName(apiLogItem.actionType)
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.action(),
          createValue: (apiLogItem: ApiLogItem) => new TextTableValue({
            value: apiLogItem.shortAction
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.httpStatus(),
          createValue: (apiLogItem: ApiLogItem) => new TextTableValue({
            value: apiLogItem.httpStatus?.toString()
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.resultType(),
          createValue: (apiLogItem: ApiLogItem) => new TextTableValue({
            value: this.detectApiLogItemResultTypeDisplayName(apiLogItem.resultType)
          })
        }),
        new TableColumn<ApiLogItem>({
          title: coreTextProvider.user(),
          createValue: (apiLogItem: ApiLogItem) => apiLogItem.user ? new TextTableValue({
            value: this.formatUserInfo(apiLogItem.user),
            url: coreUrlProvider.buildAdminUrl({
              id: apiLogItem.user!.id!
            })
          }) : null
        })
      ],
      buildFilterObject: async() => ({
        serviceType: undefined,
        httpStatus: undefined,
        direction: undefined,
        resultType: undefined,
        startedAt: undefined,
        actionType: undefined,
        user: undefined
      }),
      filterFormFields: [
        new SingleSelectFormField<
          ApiLogItemsFilter,
          never,
          ApiLogItemServiceType
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemServiceType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.service(),
          getValue: (filter: ApiLogItemsFilter) => filter.serviceType,
          setValue: (
            filter: ApiLogItemsFilter,
            type: ApiLogItemServiceType | null
          ) => ({
            ...filter,
            serviceType: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemServiceType) => type.valueOf(),
          getOptionText: (type: ApiLogItemServiceType) => this.detectApiLogItemServiceTypeDisplayName(type)
        }),
        new SingleSelectFormField<
          ApiLogItemsFilter,
          never,
          ApiLogItemDirection
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemDirection),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.direction(),
          getValue: (filter: ApiLogItemsFilter) => filter.direction,
          setValue: (
            filter: ApiLogItemsFilter,
            type: ApiLogItemDirection | null
          ) => ({
            ...filter,
            direction: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemDirection) => type.valueOf(),
          getOptionText: (type: ApiLogItemDirection) => this.detectApiLogItemDirectionDisplayName(type)
        }),
        new SingleSelectFormField<
          ApiLogItemsFilter,
          never,
          ApiLogItemActionType
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemActionType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.actionType(),
          getValue: (filter: ApiLogItemsFilter) => filter.actionType,
          setValue: (
            filter: ApiLogItemsFilter,
            type: ApiLogItemActionType | null
          ) => ({
            ...filter,
            actionType: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemActionType) => type.valueOf(),
          getOptionText: (type: ApiLogItemActionType) => this.detectApiLogItemActionTypeDisplayName(type)
        }),
        new NumberFormField<ApiLogItemsFilter, never>({
          getTitle: () => coreTextProvider.httpStatus(),
          getValue: (filter: ApiLogItemsFilter) => filter.httpStatus,
          setValue: (filter: ApiLogItemsFilter, httpStatus: number | null): ApiLogItemsFilter => ({
            ...filter,
            httpStatus
          }),
          getErrors: () => undefined
        }),
        new SingleSelectFormField<
          ApiLogItemsFilter,
          never,
          ApiLogItemResultType
        >({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(ApiLogItemResultType),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.resultType(),
          getValue: (filter: ApiLogItemsFilter) => filter.resultType,
          setValue: (
            filter: ApiLogItemsFilter,
            type: ApiLogItemResultType | null
          ) => ({
            ...filter,
            resultType: type
          }),
          getErrors: () => undefined,
          getOptionId: (type: ApiLogItemResultType) => type.valueOf(),
          getOptionText: (type: ApiLogItemResultType) => this.detectApiLogItemResultTypeDisplayName(type)
        }),
        new DateFormField<ApiLogItemsFilter, never>({
          getTitle: () => coreTextProvider.startedAt(),
          getValue: (filter: ApiLogItemsFilter) => filter.startedAt,
          setValue: (filter: ApiLogItemsFilter, startedAt: Date | null | undefined): ApiLogItemsFilter => ({
            ...filter,
            startedAt
          }),
          getErrors: () => undefined
        }),
        new SingleSelectFormField<
          ApiLogItemsFilter,
          never,
          User
        >({
          isSearchBarVisible: true,
          getObjects: async({
            query
          }) => {
            return await this.getUsersUseCase.call({
              filter: {
                roleType: UserRoleType.ADMIN,
                query
              }
            })
          },
          getTitle: () => coreTextProvider.user(),
          getValue: (filter: ApiLogItemsFilter) => filter.user,
          setValue: (
            filter: ApiLogItemsFilter,
            user: User | null
          ) => ({
            ...filter,
            user
          }),
          getErrors: () => undefined,
          getOptionId: (user: User) => isPresent(user.id) ? user.id.toString() : "",
          getOptionText: (user: User) => this.formatUserInfo(user)
        })
      ]
    })
  }

  private detectApiLogItemServiceTypeDisplayName(
    type: ApiLogItemServiceType | null | undefined
  ): string | null | undefined {
    if (isBlank(type)) return undefined

    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case ApiLogItemServiceType.DA_DATA:
        return coreTextProvider.daDataServiceType()
      case ApiLogItemServiceType.CDEK:
        return coreTextProvider.cdekServiceType()
      case ApiLogItemServiceType.SMS_AERO:
        return coreTextProvider.smsAeroServiceType()
      case ApiLogItemServiceType.YOOKASSA:
        return coreTextProvider.yookassaServiceType()
      case ApiLogItemServiceType.SYNCHRONIZATION:
        return coreTextProvider.synchronizationServiceType()
      case ApiLogItemServiceType.DOLYAME:
        return coreTextProvider.dolyameServiceType()
      case ApiLogItemServiceType.FIREBASE_CLOUD_MESSAGING:
        return coreTextProvider.firebaseCloudMessagingServiceType()
      case ApiLogItemServiceType.HUAWEI_PUSH_KIT:
        return coreTextProvider.huaweiPushKitServiceType()
      default:
        return coreTextProvider.unknownServiceType()
    }
  }

  private detectApiLogItemDirectionDisplayName(
    type: ApiLogItemDirection | null | undefined
  ): string | null | undefined {
    if (isBlank(type)) return undefined

    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case ApiLogItemDirection.OUTGOING:
        return coreTextProvider.outgoing()
      case ApiLogItemDirection.INCOMING:
        return coreTextProvider.incoming()
    }
  }

  private detectApiLogItemActionTypeDisplayName(
    type: ApiLogItemActionType | null | undefined
  ): string | null | undefined {
    if (isBlank(type)) return undefined

    return type.toString()
  }

  private detectApiLogItemResultTypeDisplayName(
    type: ApiLogItemResultType | null | undefined
  ): string | null | undefined {
    if (isBlank(type)) return undefined

    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case ApiLogItemResultType.CLIENT_ERROR:
        return coreTextProvider.clientError()
      case ApiLogItemResultType.MISSING:
        return coreTextProvider.missing()
      case ApiLogItemResultType.SERVER_ERROR:
        return coreTextProvider.serverError()
      case ApiLogItemResultType.REDIRECT:
        return coreTextProvider.redirect()
      case ApiLogItemResultType.SUCCESS:
        return coreTextProvider.success()
      case ApiLogItemResultType.UNKNOWN:
        return coreTextProvider.unknown()
    }
  }

  private formatUserInfo(user: User | null | undefined) {
    const emailAddress = user?.emailAddress
    const fullName = [
      user?.profile?.firstName,
      user?.profile?.lastName
    ].filter(value => isPresent(value))

    if (fullName.length === 0) return emailAddress

    return [fullName.join(" "), emailAddress].join("\n")
  }
}
