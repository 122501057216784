import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import DiscountRule from "../../../../../core/domain/entities/discount-rules/DiscountRule"
import DiscountRuleErrorsObject from "../../../../../core/domain/entities/discount-rules/DiscountRuleErrorsObject"
import DiscountRuleError from "../../../../../core/domain/entities/discount-rules/DiscountRuleError"
import GetDiscountRuleUseCase from "../../../../discount-rules-core/domain/use-cases/GetDiscountRuleUseCase"
import CreateDiscountRuleUseCase from "../../../../discount-rules-core/domain/use-cases/CreateDiscountRuleUseCase"
import UpdateDiscountRuleUseCase from "../../../../discount-rules-core/domain/use-cases/UpdateDiscountRuleUseCase"
import DestroyDiscountRuleUseCase from "../../../../discount-rules-core/domain/use-cases/DestroyDiscountRuleUseCase"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import ProductsSetCondition from "../../../../../core/domain/entities/products-set-condition/ProductsSetCondition"
import ProductsSetErrorsObject from "../../../../../core/domain/entities/products-sets/ProductsSetErrorsObject"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import { v4 as uuidv4 } from "uuid"
import ProductsSet from "../../../../../core/domain/entities/products-sets/ProductsSet"
import { ProductsSetConditionsFields } from "../../../../../core/presentation/fields/ProductsSetConditionsFields"
import GetPropertyValuesUseCase from "../../../../property-values-core/domain/use-cases/GetPropertyValuesUseCase"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import CalculationType from "../../../../../core/domain/entities/calculators/CalculationType"
import Calculator from "../../../../../core/domain/entities/calculators/Calculator"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import assertNever from "../../../../../../sqadmin/lib/assertNever"
import { Decimal } from "decimal.js"
import DecimalFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DecimalFormField"
import GetPropertiesUseCase from "../../../../properties-core/domain/use-cases/GetPropertiesUseCase"
import GetProductCategoriesUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/GetProductCategoriesUseCase"
import GetProductsUseCase from "../../../../products-core/domain/use-cases/products/GetProductsUseCase"

export default class DiscountRuleViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getDiscountRuleUseCase: GetDiscountRuleUseCase
  private readonly createDiscountRuleUseCase: CreateDiscountRuleUseCase
  private readonly updateDiscountRuleUseCase: UpdateDiscountRuleUseCase
  private readonly destroyDiscountRuleUseCase: DestroyDiscountRuleUseCase
  private readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
  private readonly getPropertiesUseCase: GetPropertiesUseCase
  private readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
  private readonly getProductsUseCase: GetProductsUseCase
  private readonly discountRuleId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    DiscountRule,
    DiscountRuleError,
    DiscountRuleErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getDiscountRuleUseCase: GetDiscountRuleUseCase
    readonly createDiscountRuleUseCase: CreateDiscountRuleUseCase
    readonly updateDiscountRuleUseCase: UpdateDiscountRuleUseCase
    readonly destroyDiscountRuleUseCase: DestroyDiscountRuleUseCase
    readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
    readonly getPropertiesUseCase: GetPropertiesUseCase
    readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
    readonly getProductsUseCase: GetProductsUseCase
    readonly discountRuleId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getDiscountRuleUseCase = parameters.getDiscountRuleUseCase
    this.createDiscountRuleUseCase = parameters.createDiscountRuleUseCase
    this.updateDiscountRuleUseCase = parameters.updateDiscountRuleUseCase
    this.destroyDiscountRuleUseCase = parameters.destroyDiscountRuleUseCase
    this.getProductCategoriesUseCase = parameters.getProductCategoriesUseCase
    this.getPropertiesUseCase = parameters.getPropertiesUseCase
    this.getPropertyValuesUseCase = parameters.getPropertyValuesUseCase
    this.getProductsUseCase = parameters.getProductsUseCase
    this.discountRuleId = parameters.discountRuleId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    DiscountRule,
    DiscountRuleError,
    DiscountRuleErrorsObject
  > {
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.discountRuleId),
      getObjectUrl: (discountRule) => {
        return this.coreUrlProvider.buildDiscountRuleUrl({
          id: discountRule.id!
        })
      },
      buildObject: async() => ({
        id: undefined,
        priority: undefined,
        calculator: undefined,
        name: undefined,
        productsSet: undefined
      }),
      loadObject: async() => {
        return await this.getDiscountRuleUseCase.call({ discountRuleId: this.discountRuleId! })
      },
      createObject: async({ object: discountRule }) => {
        return await this.createDiscountRuleUseCase.call({ discountRule })
      },
      updateObject: async({ object: discountRule }) => {
        return await this.updateDiscountRuleUseCase.call({
          discountRuleId: this.discountRuleId!,
          discountRule
        })
      },
      destroyObject: async() => {
        return await this.destroyDiscountRuleUseCase.call({ discountRuleId: this.discountRuleId! })
      },
      getErrorsObject: ({ error: discountRuleError }) => discountRuleError
        ?.errorsObject,
      formFields: [
        this.createDiscountRuleNameFormField(),
        this.createDiscountRulePriority(),
        this.createCalculationTypeFormField(),
        this.createCalculatorValue(),
        this.createRoundingDecimalsCount(),
        this.createDiscountRuleProductsSetConditionsFormField()
      ]
    })
  }

  private createDiscountRuleNameFormField() {
    return new StringFormField<DiscountRule, DiscountRuleErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().name(),
      getValue: (discountRule: DiscountRule) => discountRule.name,
      setValue: (discountRule: DiscountRule, name: string) => ({ ...discountRule, name }),
      getErrors: (discountRuleErrorsObject?: DiscountRuleErrorsObject) => discountRuleErrorsObject
        ?.attributes
        ?.name
    })
  }

  private createDiscountRulePriority() {
    return new NumberFormField<DiscountRule, DiscountRuleErrorsObject>({
      getTitle: () => this.coreI18n.getTextProvider().priority(),
      getValue: (discountRule: DiscountRule) => discountRule.priority,
      setValue: (discountRule: DiscountRule, priority: number | null) => ({ ...discountRule, priority }),
      getErrors: (discountRuleErrorsObject?: DiscountRuleErrorsObject) => discountRuleErrorsObject
        ?.attributes
        ?.priority
    })
  }

  private createCalculationTypeFormField() {
    return new SingleSelectFormField<DiscountRule, DiscountRuleErrorsObject, CalculationType>({
      isSearchBarVisible: false,
      getObjects: async() => {
        return {
          type: "success",
          data: {
            objects: [CalculationType.SUBTRACT_PERCENTAGE, CalculationType.SUBTRACT_FIXED],
            page: { hasMore: false }
          }
        }
      },
      getTitle: () => this.coreI18n.getTextProvider().calculationType(),
      getValue: (discountRule: DiscountRule) => discountRule.calculator?.calculationType,
      setValue: (discountRule: DiscountRule, calculationType: CalculationType | null | undefined): DiscountRule => {
        return {
          ...discountRule,
          calculator: {
            ...discountRule.calculator ?? this.createCalculator(),
            calculationType,
            value: undefined,
            roundingDecimalsCount: undefined
          }
        }
      },
      getErrors: (discountRuleErrorsObject?: DiscountRuleErrorsObject) => discountRuleErrorsObject
        ?.calculator
        ?.attributes
        ?.calculationType,
      getOptionId: (type: CalculationType) => type.valueOf(),
      getOptionText: (type: CalculationType) => this.detectCalculationTypeDisplayName(type)
    })
  }

  private createCalculatorValue() {
    return new DecimalFormField<DiscountRule, DiscountRuleErrorsObject>({
      getVisible: (discountRule: DiscountRule) => isPresent(discountRule.calculator?.calculationType),
      getTitle: () => this.coreI18n.getTextProvider().value(),
      getValue: (discountRule: DiscountRule) => discountRule.calculator?.value,
      setValue: (discountRule: DiscountRule, value: Decimal | null | undefined) => {
        return {
          ...discountRule,
          calculator: {
            ...discountRule.calculator ?? this.createCalculator(),
            value
          }
        }
      },
      getErrors: (discountRuleErrorsObject?: DiscountRuleErrorsObject) => discountRuleErrorsObject
        ?.calculator
        ?.attributes
        ?.value
    })
  }

  private createRoundingDecimalsCount() {
    return new NumberFormField<DiscountRule, DiscountRuleErrorsObject>({
      getVisible: (discountRule: DiscountRule) => isPresent(discountRule.calculator?.calculationType),
      getTitle: () => this.coreI18n.getTextProvider().roundingDecimalsCount(),
      getValue: (discountRule: DiscountRule) => discountRule.calculator?.roundingDecimalsCount,
      setValue: (discountRule: DiscountRule, roundingDecimalsCount: number | null | undefined) => {
        return {
          ...discountRule,
          calculator: {
            ...discountRule.calculator ?? this.createCalculator(),
            roundingDecimalsCount
          }
        }
      },
      getErrors: (discountRuleErrorsObject?: DiscountRuleErrorsObject) => discountRuleErrorsObject
        ?.calculator
        ?.attributes
        ?.roundingDecimalsCount
    })
  }

  private createCalculator(): Calculator {
    return {
      calculationType: undefined,
      id: undefined,
      value: undefined,
      roundingDecimalsCount: undefined
    }
  }

  private detectCalculationTypeDisplayName(type: CalculationType) {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    switch (type) {
      case CalculationType.SUBTRACT_PERCENTAGE:
        return coreTextProvider.calculationTypeSubtractPercentage()
      case CalculationType.SUBTRACT_FIXED:
        return coreTextProvider.calculationTypeSubtractFixed()
      case CalculationType.FIXED:
      case CalculationType.PERCENTAGE:
        // такой тип не реализован для данного правил скидок
        return ""
      default:
        assertNever(type)
    }
  }

  private createDiscountRuleProductsSetConditionsFormField() {
    return new ListFormField<DiscountRule, DiscountRuleErrorsObject, ProductsSetCondition, ProductsSetErrorsObject>({
      addObjectButtonName: this.coreI18n.getTextProvider().add(),
      getTitle: () => this.coreI18n.getTextProvider().productsSetConditions(),
      getValue: (discountRule: DiscountRule): ProductsSetCondition[] | null | undefined => {
        return discountRule.productsSet && discountRule.productsSet.conditions
      },
      getErrors: (discountRuleErrorsObject?: DiscountRuleErrorsObject) => discountRuleErrorsObject
        ?.attributes
        ?.productsSet,
      setValue: (discountRule: DiscountRule, productsSetConditions: ProductsSetCondition[] | null): DiscountRule => {
        return {
          ...discountRule,
          productsSet: {
            ...discountRule.productsSet ?? this.createProductsSet(),
            conditions: productsSetConditions
          }
        }
      },
      getNestedObjectId: (productsSetCondition: ProductsSetCondition) => productsSetCondition.clientId!,
      getNestedObjectTitle: (_: ProductsSetCondition, index: number) => {
        return this.coreI18n.getTextProvider().productsSetConditionWithNumber({ number: index + 1 })
      },
      getNestedErrorsObject: (_: ProductsSetCondition, discountRuleErrorsObject?: DiscountRuleErrorsObject) => {
        return discountRuleErrorsObject?.productsSet
      },
      hasNestedErrorsObjects: (discountRuleErrorsObject?: DiscountRuleErrorsObject) => {
        return isPresent(discountRuleErrorsObject?.productsSet)
      },
      buildNewValue: (): ProductsSetCondition => this.createProductsSetCondition(),
      fields: this.createProductsSetConditionsFields().createFields()
    })
  }

  private createProductsSetCondition(): ProductsSetCondition {
    return {
      clientId: uuidv4()
    }
  }

  private createProductsSet(): ProductsSet {
    return {
      id: undefined,
      conditions: undefined,
      clientId: uuidv4()
    }
  }

  private createProductsSetConditionsFields() {
    return new ProductsSetConditionsFields({
      coreI18n: this.coreI18n,
      getProductCategories: async({ query, lastObjectId }) => await this.getProductCategoriesUseCase.call({
        filter: { query },
        pagination: { id: lastObjectId }
      }),
      getProperties: async({ query, lastObjectId }) => await this.getPropertiesUseCase.call({
        filter: { query },
        pagination: { id: lastObjectId }
      }),
      getPropertyValues: async({
        query,
        propertyId,
        lastObjectId
      }) => await this.getPropertyValuesUseCase.call({
        query,
        propertyId,
        pagination: { id: lastObjectId }
      }),
      getProducts: async({ filter, query, lastObjectId }) => await this.getProductsUseCase.call({
        filter,
        query,
        pagination: { id: lastObjectId }
      }),
      findProductsSetForCondition: ({
        productsSetCondition
      }) => this.findProductsSetForCondition({ productsSetCondition })
    })
  }

  private findProductsSetForCondition({
    productsSetCondition
  }: {
    readonly productsSetCondition: ProductsSetCondition
  }): ProductsSet | undefined {
    const discountRule: DiscountRule | undefined = this.objectPresentationLogic.getObject()

    if (isBlank(discountRule)) {
      return undefined
    }

    for (const searchingProductsSetCondition of discountRule.productsSet?.conditions ?? []) {
      if (searchingProductsSetCondition.clientId === productsSetCondition.clientId) {
        return discountRule.productsSet!
      }
    }

    return undefined
  }
}
